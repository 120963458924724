import { environment } from "@environments/environment";

const VERSION = 'v1';

const URL_CONFIG = {
    baseURL: `${VERSION}`,
    commScopeURL: `${VERSION}/commscope`,
    supplyOneURL: `${VERSION}/supplyone`,
    partnerURL: `${VERSION}/partner`,
    partnersURL: `${VERSION}/partners`,
    publishURL: `${VERSION}/publish`
};


export const apiConfig = {
    transferfiles: getURL(`${URL_CONFIG.supplyOneURL}/transferfiles`),
    groups: getURL(`${URL_CONFIG.commScopeURL}/groups`),
    getModelsInfo: getURL(`${URL_CONFIG.commScopeURL}/get_models_info`),
    getPlotsInfo: getURL(`${URL_CONFIG.commScopeURL}/get_plots_info`),
    getJson: getURL(`${URL_CONFIG.commScopeURL}/get_json`),
    model: getURL(`${URL_CONFIG.commScopeURL}/model`),
    plot: getURL(`${URL_CONFIG.commScopeURL}/plot`),
    uploadModelConfig: getURL(`${URL_CONFIG.commScopeURL}/upload_model_config`),
    uploadPlotConfig: getURL(`${URL_CONFIG.commScopeURL}/upload_plot_config`),
    runModel: getURL(`${URL_CONFIG.commScopeURL}/run_model`),
    generatePlot: getURL(`${URL_CONFIG.commScopeURL}/generate_plot`),
    clone: getURL(`${URL_CONFIG.commScopeURL}/clone`),
    getPartnersCustomersInfo: getURL(`${URL_CONFIG.commScopeURL}/get_partners_customers_info`),
    getCSVData: getURL(`${URL_CONFIG.commScopeURL}/get_csv_data`),
    successRunStatus: getURL(`${URL_CONFIG.commScopeURL}/success_run_status`),
    createCustomer: getURL(`${URL_CONFIG.partnerURL}`),
    deleteCustomer: getURL(`${URL_CONFIG.partnerURL}`),
    createPartner: getURL(`${URL_CONFIG.partnersURL}`),
    createUpdateFeature: getURL(`${URL_CONFIG.partnerURL}`),
    getCurrentUpdateStatus: getURL(`${URL_CONFIG.baseURL}/get_update_status`),
    downloadModelOutput: getURL(`${URL_CONFIG.commScopeURL}/download_file`),
    downloadPlotOutput: getURL(`${URL_CONFIG.commScopeURL}/download_file`),
    plotOutputs: getURL(`${URL_CONFIG.commScopeURL}/plot_outputs`),
    deleteModel: getURL(`${URL_CONFIG.commScopeURL}/delete_model`),
    deletePlot: getURL(`${URL_CONFIG.commScopeURL}/delete_plot`),
    publishModel: getURL(`${URL_CONFIG.publishURL}`),
    getAOD: getURL(`${URL_CONFIG.commScopeURL}/get_aod`),
    getPublishInfo: getURL(`${URL_CONFIG.publishURL}/publish_meta_info`),
    updatePublishInfo: getURL(`${URL_CONFIG.publishURL}/publish_meta_info`),
    addPublishInfo: getURL(`${URL_CONFIG.publishURL}/publish_meta_info`),
    deletePublishInfo: getURL(`${URL_CONFIG.publishURL}/publish_meta_info`),
    getExtraPublishCols: getURL(`${URL_CONFIG.publishURL}/get_granularity_lvls`),
    getExtraPublishColValues: getURL(`${URL_CONFIG.publishURL}/get_granularity_values`),
    getPublishedModels: getURL(`${URL_CONFIG.commScopeURL}/publish_models_info`),
    downloadPublishMetadata: getURL(`${URL_CONFIG.publishURL}/download_file`),
    uploadPublishMetadata: getURL(`${URL_CONFIG.publishURL}/upload_file`),
    uploadAdhocProcessConfig: getURL(`${URL_CONFIG.commScopeURL}/upload_adhoc_config`),
    triggerPipeline: getURL(`${URL_CONFIG.commScopeURL}/trigger_pipeline`),
};

function getURL(url: string) {
    return `${environment.api}/${url}`;
}
