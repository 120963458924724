import { MatDialogConfig } from "@angular/material/dialog";
import { Status } from "@app/shared/enums/status.enum";

export const CSV_DTME_MAPPING = {
  'LOWER_BOUND_PARAMS_CSV': 'LOWER_BOUND_PARAMS_DTME',
  'UPPER_BOUND_PARAMS_CSV': 'UPPER_BOUND_PARAMS_DTME',
  'SEGMENT_LADDERING_PARAMS_CSV': 'SEGMENT_LADDERING_PARAMS_DTME',
  'SEGMENT_LADDERING_MULTIPLE_PARAMS_CSV': 'SEGMENT_LADDERING_MULTIPLE_PARAMS_DTME',
  'SEGMENT_LADDERING_OTHER_PARAMS_CSV': 'SEGMENT_LADDERING_OTHER_PARAMS_DTME',
  'CAP_MOVEMENT_PARAMS_CSV': 'CAP_MOVEMENT_PARAMS_DTME',
  'FAMILY_PARAMS_CSV': 'FAMILY_PARAMS_DTME',
  'COVERAGE_PARAMS_CSV': 'COVERAGE_PARAMS_DTME',
  'END_USER_SEGMENT_MAPPING_CSV': 'END_USER_SEGMENT_MAPPING_DTME',
  'OVERRIDES_CSV': 'OVERRIDES_DTME',
  'MIN_BAND_WIDTH_PARAMS_CSV': 'MIN_BAND_WIDTH_PARAMS_DTME',
  'SPARSITY_PARAMS_CSV': 'SPARSITY_PARAMS_DTME',
  'CSKU_PARAMS_CSV': 'CSKU_PARAMS_DTME',
  'SCALING_FACTOR_PARAMS_CSV': 'SCALING_FACTORS_DTME',
  'TARGET_WT_PARAMS_CSV': 'TARGET_WT_PARAMS_DTME',
  'SEGMENTATION_MAP_CSV': 'SEGMENTATION_MAP_DTME',
  'TIME_WEIGHTING_PARAMS_CSV': 'TIME_WEIGHTING_PARAMS_DTME',
  'TIME_FILTERS_PARAMS_CSV': 'TIME_FILTERS_PARAMS_DTME',
  'USER_CAPPING_BANDS_CSV': 'USER_CAPPING_BANDS_DTME',
  'BORROW_BANDS_PARAMS_CSV': 'BORROW_BANDS_PARAMS_DTME',
};

export const CSV_DTME_MAPPING_VALUES = Object.keys(CSV_DTME_MAPPING).map(key => CSV_DTME_MAPPING[key]);

export const PLOT_CSV_DTME_MAPPING = {
  'xlabel_params_csv': 'xlabel_params_dtme',
  'plot_subset_params_csv': 'plot_subset_params_dtme'
};

export const PLOT_CSV_DTME_MAPPING_VALUES = Object.keys(PLOT_CSV_DTME_MAPPING).map(key => PLOT_CSV_DTME_MAPPING[key]);

export const FEATURES_TO_EXCLUDE = {
  PRODUCT_PLUS: true
};


export const STATUS_ICON_CLASS_MAP = {
  [Status.Completed]: 'fas fa-check-circle',
  [Status.Failed]: 'fas fa-exclamation-circle',
  [Status.Running]: 'fas fa-clock',
  [Status.BeingConfigured]: 'fas fa-cog',
  [Status.Configured]: 'fas fa-check fa-fw'
};

export const PUBLISH_STATUS_ICON_CLASS_MAP = {
  [Status.Undefined]: 'fas fa-paper-plane',
  [Status.Completed]: 'fas fa-paper-plane completed',
  [Status.Failed]: 'fas fa-paper-plane failed',
  [Status.Running]: 'fas fa-paper-plane faa-flash animated running',
};


export const DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
  width: '450px',
  minHeight: '120px'
};

export const PRICE_BAND_RUN_LIST_PAGE_OPTIONS = [5, 10, 20, 50, 100];
export const PRICE_BAND_RUN_LIST_DEFAULT_PAGE_SIZE = 50;
export const PRICE_BAND_RUN_GROUP_DEFAULT_PAGE_SIZE = 10;
export const PRICE_BAND_RUN_LIST_DEFAULT_PAGE_INDEX = 0;
export const AUTH_CONFIRM_POPUP_TIME_IN_MINS = 15;
export const VALID_MODEL_PLOT_NAME_PATTERN = "^[0-9a-zA-Z_]+$";
export const MAX_MODEL_PLOT_NAME_LENGTH = 50;

export const LIST_SCREEN_COLUMNS_TO_EXCLUDE = ["execution_id", "id", "publish_id", "effective_date", "publish_name", "publish_dtme", "status_publish",	"execution_id_publish"]
export const MILLISECONDS_IN_A_SEC = 1000
export const MILLISECONDS_IN_A_MIN = 60 * MILLISECONDS_IN_A_SEC
export const MILLISECONDS_IN_AN_HOUR = 60 * MILLISECONDS_IN_A_MIN

export const LANDING_PAGE_INFO = {
  title: 'Landing Page',
  route: '/landing-page',
  icon: 'fas fa-home',
  dataCy: 'landingPage_button'
};

export const PRICE_BAND_RUN_SETUP_INFO = {
  title: 'Price Band Run Setup',
  route: '/price-band-setup',
  icon: 'fas fa-shapes',
  dataCy: 'priceBandRunSetup_button',
};

export const PRICE_BAND_RUN_LIST_INFO = {
  title: 'Price Band Run List',
  route: '/price-band-run',
  icon: 'fas fa-piggy-bank',
  dataCy: 'priceBandRunList_button',
  fragment: 'list'
};

export const PRICE_BAND_REVIEW_INFO = {
  title: 'Price Band Review',
  route: '/price-band-review',
  icon: 'fas fa-poll',
  dataCy: 'priceBandReview_button',
};

export const CSS_DASHBOARD_INFO = {
  title: 'CSS Dashboard',
  route: '/css-dashboard',
  icon: 'fas fa-tachometer-alt',
  dataCy: 'CSSDashboard_button',
  fragment: 'base-plus'
};

export const PAGES_INFO_WITH_ORDER = [
  LANDING_PAGE_INFO,
  PRICE_BAND_RUN_LIST_INFO,
  PRICE_BAND_RUN_SETUP_INFO,
  PRICE_BAND_REVIEW_INFO,
  CSS_DASHBOARD_INFO,
];

export const PUBLISH_RUN_LIST_PAGE_OPTIONS = [5, 10, 20, 50, 100];
export const PUBLISH_RUN_LIST_DEFAULT_PAGE_SIZE = 50;
export const PUBLISH_RUN_LIST_DEFAULT_PAGE_INDEX = 0;
export const PUBLISH_SCREEN_COLUMNS_TO_EXCLUDE = ["id", "publish_id", "status", "execution_id", "is_deletable"];

export const UNSUCCESSFUL_RUN_DISABLED_CONTROLS = ['IS_CAPPING_BAND', 'IS_RESULT_COMPARISON_ENABLED', 'APPLY_MODEL_SUBSET_PARAMS']

export const PLOT_VARIABLE_YLABEL_MAP = {
  'price': 'price ($)',
  'margin': 'margin (%)',
  'markup': 'markup (%)',
  'discount': 'discount (%)',
  'into_stock_discount': 'into stock discount (%)'
}

  export const CUSTOM_DATE_FORMATS = {
    parse: {
      dateInput: 'YYYY-MM-DD',
    },
    display: {
      dateInput: 'YYYY-MM-DD',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
  };
   